@import "color.scss";

$theme-colors: (
        primary: $primary,
        success: $success,
        info: $info,
        warning: $warning,
        danger: $danger,
        secondary: $secondary,
        light: $light,
        dark: $dark
);

// Spacing

$spacer: 1.5rem;

$spacers: (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .50),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3)
) !default;

// Fonts
$font-family-sans-serif: "Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-content: "Fira Sans Light", "Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

$font-size-root: 16px;

//Navbar
$navbar-padding-y: 0.75rem;
$navbar-padding-x: 0;