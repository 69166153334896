// @import "../../../node_modules/bootstrap/scss/functions";
// @import "../../../node_modules/bootstrap/scss/variables";
// @import "../../../node_modules/bootstrap/scss/mixins";

$transparent-bg: rgba(200, 200, 200, 0.2) !default;
$heading-color: #464855;
$content-color: #6B6F82;
$background-light-grey: #e8e7e3 !default;
$background-lighter-grey: #f0efeb !default;
$font-background-light-grey: #9c9b99 !default;
$font-hover-background-light-grey: #5e5e5c !default;

// $body-bg: var(--body-bg);
$body-bg: #fff;
$card-bg: var(--card-bg);
$heading-color: var(--heading-color);
$form-control-bg: var(--form-control-bg);

$primary: #097584;
$success: #FFC101;
$info: #3A1025;
$warning: #f2bf42;
$danger: #ff586b;
$secondary: #e0e0e0;
$light: #f8f9fa;
$dark: #343a40;
$gray: #e0e0e0;
$black: #231F20;
$black1: #101820;
$black2: #1d2429;

$color1:$primary;
$color2:$secondary;
$color3:$info;

:export {
  black1: $black2;
  black2: $black2;
  cardBg: $card-bg;
  gray: $gray;
  warning: $warning;
  lightgray: $light;
}

$social-facebook: #3b5998;
$social-twitter: #55acee;
$social-pinterest: #cc2127;
$social-google: #dd4b39;
$social-linkedin: #0976b4;
$social-dribbble: #ea4c89;
$social-github: #333333;
$social-youtube: #e52d27;
$social-instagram: #125688;
$social-reddit: #ff4500;
$social-tumblr: #35465c;
$social-behance: #1769ff;
