@import 'color.scss';


a {
  color:var(--text-color) !important;
}
a:hover{
  color:var(--color1) !important;
}

h5.card-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.form-control:focus {
  outline-color: $color2;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  background-clip: none;
  &:focus {
    background-color: transparent;
  }
}

.fadeOut {
  width: 0px;
  opacity: 0;
  visibility: hidden;
  font-size: 0px;
  margin: 0px;
  padding: 0px !important;
  border: 0px;
  border-spacing: 0px;
  border-top-width: 0px;
  line-height: 0px;
  overflow: hidden;
}

.basic-single > div.select__control--menu-is-open {
  border-color: $color1 !important;
  box-shadow: 0 0 0 1px $color1 !important;
}

.basic-single > div.select__control--is-focused {
  border-color: $color1 !important;
  box-shadow: 0 0 0 1px $color1 !important;
}

.select__menu-list > div.select__option--is-selected {
  background-color: $color1;
}

.select__menu-list > div.select__option--is-focused {
  background-color: $color1;
}

button.dropdown-item {
  outline-color: $color3 !important;
}

$white-color: #fcf5ed;

:export {
  whitecolor: $white-color;
}

.select__option--is-focused {
  background-color: $color2 !important;
  color: white !important;
}

.select__option--is-selected {
  background-color: $color1 !important;
  color: white !important;
}

@media screen and (max-width: 500px) {
  .settings-label {
    text-align: left !important;
  }
}

.table-options {
  transition: height .5s ease-in-out;
  // height: 100%;
}

.table-options-folded {
  height: 0;
  overflow: hidden;
}

textarea {
  color: $heading-color;
  background-color: $form-control-bg;
}

.form-control{
  background-color: $form-control-bg !important;
}

th {
  color: var(--heading-color);
}

td {
  color: var(--text-color);
}

div.dropdown-menu.show {
  background-color: var(--card-bg);
}

.form-control {
  border-color: var(--border-color) !important;
  color: var(--text-color) !important;
  background-color: transparent;
}

td, th {
  border-color: var(--border-color) !important;
}

.dropdown-item {
  color: var(--text-color);

  &:hover {
    background-color: var(--body-bg) !important;
  }
}

.white-text {
  color: white !important;
}

.Modal {
  position: absolute;
  width:80vw;
  background-color: white;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding:20px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(1,1,1,0.3);
  display:flex;
  justify-content: center;
  align-items: center;
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}

.ant-picker-cell-selected>.ant-picker-cell-inner{
  background:var(--color1) !important;
  border-color:var(--color1) !important;
}
a.ant-picker-today-btn{
  color:var(--color1) !important;
}
a.ant-picker-now-btn{
  color:var(--color1) !important;
}
.ant-btn{
  background-color:var(--color1) !important;
}